<template>
    <div class="component-video-editor">
        <div class="video-editor">

            <div class="row row-items video-editor-container">
                <div class="col-item col-12 col-lg-4">

                    <!-- SECTION LEFT MENU -->
                    <div class="menu">
                        <PositionStepsMenu
                            :class="{ 'disable-element': canChangeStep }"
                            :steps="positionSteps"
                            v-model:selected="actualStepId"
                        />
                    </div>
                </div>

                <div class="col-item col-12 col-lg-8">

                    <!-- SECTION CONTENT VIDEO EDITOR-->
                    <div class="content">
                        <PositionStepHeader :selected-step="selectedStep" />

                        <div class="video-editor-handler">
                            <div class="video-preview">
                                <mux-player
                                    v-if="selectedStep?.video?.muxId"
                                    :playback-id="selectedStep?.video?.muxId"
                                ></mux-player>

                                <video
                                    class="video-preview-element"
                                    id="video-preview-source"
                                    v-if="!selectedStep?.video?.muxId && selectedStep?.video?.preview && resetVideoPreview"
                                    controls
                                    playsinline
                                    disablepictureinpicture
                                    disableremoteplayback
                                >
                                    <source :src="selectedStep?.video?.preview">
                                </video>

<!--                                <video-->
<!--                                    v-show="!selectedStep?.video?.preview && streamVideo && streamAudio"-->
<!--                                    class="video-preview-element"-->
<!--                                    id="video-preview-stream"-->
<!--                                    playsinline-->
<!--                                    autoplay-->
<!--                                    disablepictureinpicture-->
<!--                                    disableremoteplayback-->
<!--                                    muted-->
<!--                                ></video>-->
                            </div>

                            <VideoStreamHandler
                                v-if="showVideoStreamHandler && !loaders.convertVideoToMp4"
                                :recording-in-progress="recordingInProgress"
                                @handle-record="handleRecord"
                            />

                            <div
                                class="spinner-wrapper d-flex flex-column align-items-center"
                                v-if="loaders.convertVideoToMp4 || loaders.uploadingVideo"
                            >
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div class="mt-2">{{ $t('videoEditor.videoIsLoading') }}</div>
                            </div>

                            <div class="video-handler">
                                <SelectVideoSource
                                    v-if="selectedStep?.video?.canSelectSource"
                                    @select-source="selectVideoSource"
                                />

                                <TrimTimeSelect
                                    v-if="selectedStep?.video?.trimData"
                                    v-model:video="selectedStep"
                                    :video-trimming="loaders.videoTrimming || loaders.videoResize || selectedStep?.video?.state === 2"
                                    @crop-video="videoTrim"
                                />

<!--                                <HorizontalProgressBar-->
<!--                                    class="mt-2"-->
<!--                                    v-if="progress.time && !loaders.convertVideoToMp4 && selectedStep?.video?.metaData?.duration"-->
<!--                                    :current-value="videoTrimProgressInPercentage"-->
<!--                                />-->

                                <HorizontalProgressBar
                                    class="mt-2"
                                    v-if="progress.time && videoTrimProgressInPercentage"
                                    :current-value="videoTrimProgressInPercentage"
                                />
                            </div>

                            <div
                                class="handle-video-source"
                                :class="{ 'disable-element': videoProcessing }"
                                v-if="selectedStep?.video?.original?.source || selectedStep?.video?.preview || selectedStep?.video?.source && mode === 'update'"
                            >
                                <MainButton
                                    v-if="selectedStep?.video?.original?.source"
                                    btn-type="submit"
                                    color-mode="white"
                                    btn-icon="recovery"
                                    :btn-text="$t('videoEditor.restoreVideo')"
                                    @click.prevent="restoreVideo"
                                />

                                <MainButton
                                    v-if="selectedStep?.video?.preview"
                                    btn-type="submit"
                                    color-mode="primary"
                                    btn-icon="trash"
                                    :btn-text="$t('videoEditor.deleteVideo')"
                                    @click.prevent="clearVideoData"
                                />

                                <MainButton
                                    v-if="selectedStep?.video?.source && mode === 'update'"
                                    btn-type="submit"
                                    color-mode="secondary"
                                    btn-icon="save"
                                    :btn-text="$t('videoEditor.saveVideo')"
                                    @click.prevent="saveVideo('save')"
                                />
                            </div>

                            <HorizontalProgressBar
                                class="mt-2"
                                v-if="updateProgress"
                                :current-value="updateProgress"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <input
                class="display-none"
                type="file"
                accept="video/mp4"
                @input="onVideoUpload"
                ref="ref_media_input"
            />
        </div>
    </div>
</template>

<script>
import PositionStepsMenu from "./elements/PositionStepsMenu.vue";
import PositionStepHeader from "./elements/PositionStepHeader.vue";
import SelectVideoSource from "./elements/SelectVideoSource.vue";
import videoEditorService from "../../functions/services/videoEditorService";
import TrimTimeSelect from "./elements/TrimTimeSelect.vue";
import { fetchFile } from "@ffmpeg/ffmpeg/dist/ffmpeg.min";
import VideoStreamHandler from "./elements/VideoStreamHandler.vue";
import HorizontalProgressBar from "./elements/HorizontalProgressBar.vue";
import MainButton from "../elements/MainButton.vue";
import {mapGetters} from "vuex";

export default {
    name: "VideoEditor",
    props: {
        positionId: {
            type: String,
            default: ''
        },
        positionStatus: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create' // create | update
        },
    },
    mixins: [ videoEditorService ],
    components: {
        MainButton,
        HorizontalProgressBar,
        VideoStreamHandler,
        TrimTimeSelect,
        SelectVideoSource,
        PositionStepHeader,
        PositionStepsMenu,
    },
    data() {
        return {
            ffmpeg: null,
            progress: {},

            actualStepId: null,
            resetVideoPreview: true,

            // video stream preview element
            streamPreviewElement: null,
            // recording stream video according to the selected media type
            streamVideo: null,
            streamAudio: null,
            finalStream: null,
            // recording function
            mediaRecorder: null,
            // true if recording is in progress
            recordingInProgress: false,

            positionSteps: [],
            updateProgress: 0,

            loaders: {
                uploadingVideo: false,
                videoTrimming: false,
                videoResize: false,
                convertVideoToMp4: false
            }
        };
    },
    watch: {
        actualStepId() {
            // reset video preview
            this.resetVideoPreview = false
            this.$nextTick(() => this.resetVideoPreview = true)
        },
        videoProcessing: {
            handler(val) {
                this.$emit('video-processing', val)
            },
            immediate: true
        },
        allVideosSaved: {
            handler(val) {
                this.$emit('all-videos-saved', val)
            },
            immediate: true
        },
        someVideoUnsaved: {
            handler(val) {
                this.$emit('some-video-unsaved', val)
            },
            immediate: true
        },
    },
    mounted() {
        // window.onbeforeunload = function() {
        //     return "Confirm refresh"
        // };

        // "-c:v", "libx264" | "libvpx-vp9", // video codec
        // "-c:a", "aac" | "libopus", // audio codec
        // "-preset", "ultrafast", // "ultrafast" | "superfast" | "veryfast" | "fast" | "medium" | "slow" | "slower" | "veryslow"

        this.initializeFfmpeg()
        this.preparePositionStepsData()

        this.initMuxPlayerScript()
    },
    beforeUnmount() {
        // if (this.ffmpeg?.isLoaded()) {
        //     this.ffmpeg?.exit()
        // }

        this.$nextTick(() => this.ffmpeg = null)
    },
    methods: {
        initMuxPlayerScript() {
            if (!document.querySelector('script[src="https://cdn.jsdelivr.net/npm/@mux/mux-player"]')) {
                const script = document.createElement("script");
                script.src = "https://cdn.jsdelivr.net/npm/@mux/mux-player";
                script.async = true;
                script.onload = () => console.log("Mux Player script loaded successfully.");
                document.head.appendChild(script);
            }
        },
        async initializeFfmpeg() {
            // console.log('initializeFfmpeg', this.ffmpeg)

            // const self = this
            this.ffmpeg = null

            // initialize FFMPEG
            // this.ffmpeg = await createFFmpeg({
            //     log: false,
            //     progress(duration) {
            //         // console.log('ffmpeg progress', duration);
            //         self.progress = duration
            //     }
            // });

            // initializing ffmpeg and checking if it initialized correctly
            // await this.ffmpeg?.load().finally(() => {
            //     if (this.ffmpeg?.isLoaded()) {
            //         console.log('FFmpeg is initialized and ready to use.');
            //     } else {
            //         console.error('FFmpeg failed to initialize.');
            //     }
            //
            //     // the second way for tracking progress
            //     // this.ffmpeg.setProgress(e => {
            //     //     console.log(e);
            //     // });
            // })

            // initialize stream preview element
            this.streamPreviewElement = document.querySelector('#video-preview-stream');
        },
        preparePositionStepsVideoData(data) {
            this.positionSteps.length = 0
            const videos = data.videos

            let canEnterAll = true
            if (data.status.id === 0 || data.status.id === 3) canEnterAll = false

            this.positionSteps = videos.map(video => {
                // const { original } = video

                let step = JSON.parse(JSON.stringify(video.question))
                step['canEnter'] = canEnterAll
                step['video'] = {
                    videoId: video.id,
                    canSelectSource: !video.original,
                    sourceType: null,
                    source: null,
                    preview: video.original,
                    metaData: null,
                    trimData: null, // slider range settings { max: 0, values: [a, b] }
                    state: video.original ? 1 : 0, // 0 - unsaved, 1 - saved, 2 - uploading, 3 - uploaded but not saved

                    // video ID from mux
                    muxId: video?.meta?.playback_ids?.[0]?.id ?? null,

                    original: {
                        source: null,
                        preview: null,
                        metaData: null,
                    }
                }

                return step
            })

            // set first (default) step
            this.positionSteps[0].canEnter = true
            this.actualStepId = this.positionSteps[0].id
        },
        preparePositionStepsData() {
            this.positionSteps.length = 0

            this.axiosGetPositionQuestions().then(res => {
                // console.log('preparePositionStepsData', res.data.data);
                const data = res.data.data
                this.positionSteps = data.map(step => {
                    step['canEnter'] = false
                    step['video'] = {
                        canSelectSource: true,
                        sourceType: null,
                        source: null,
                        preview: null,
                        metaData: null,
                        trimData: null, // slider range settings { max: 0, values: [a, b] }
                        state: 0,

                        original: {
                            source: null,
                            preview: null,
                            metaData: null,
                        }
                    }
                    return step
                })

                // set first (default) step
                this.positionSteps[0].canEnter = true
                this.actualStepId = this.positionSteps[0].id
            })
        },
        selectVideoSource(sourceType) {
            let videoData = this.selectedStep.video
            videoData['sourceType'] = sourceType

            if (sourceType === 'device') {
                this.openInputFile()
            } else if (sourceType === 'camera-user') {
                 this.initCameraRecord('user') // 'user'

                // disable select source buttons
                let videoData = this.selectedStep?.video
                videoData.canSelectSource = false
            } else if (sourceType === 'camera-environment') {
                 this.initCameraRecord('environment') // 'environment'

                // disable select source buttons
                let videoData = this.selectedStep?.video
                videoData.canSelectSource = false
            }
        },
        openInputFile() {
            this.$refs.ref_media_input.click()
        },
        async onVideoUpload(event) {
            const self = this

            let file = event.target.files[0]

            const maxSizeInBytes = 1073741824; // 1 GB
            // const maxSizeInBytes = 524288000 ; // 500MB
            if (file.size > maxSizeInBytes) {
                this.$root.$refs.ref_toast.showToast(this.$t('videoEditor.fileSizeExceedsAllowedLimit'), 'toast-danger')
                return;
            }

            // disable select source buttons
            let videoData = this.selectedStep?.video
            videoData.canSelectSource = false

            this.loaders.uploadingVideo = true

            const chunkSize = 1024 * 1024 * 100; // 100 MB
            let offset = 0;
            const chunks = [];
            let completeFile = null

            const reader = new FileReader();
            reader.onload = function () {
                chunks.push(reader.result);

                // next part
                offset += chunkSize;
                if (offset < file.size) {
                    readNextChunk();
                } else {
                    completeFile = new Blob(chunks, { type: file.type });
                    self.prepareUploadedVideo(completeFile)
                }
            };

            function readNextChunk() {
                const blob = file.slice(offset, offset + chunkSize);
                reader.readAsArrayBuffer(blob);
            }

            readNextChunk();

            // console.log('file', file)
            // const fileURL = URL.createObjectURL(file) // blob:http://localhost:8080/4ff1e8c5...

            // convert video to mp4
            // if (file.type !== 'video/mp4') {
            //     await this.ffmpeg.FS("writeFile", "video.avi", await fetchFile(file));
            //     await this.ffmpeg.run(
            //         "-i", "video.avi",
            //         "-c:v", "copy",
            //         "-c:a", "copy",
            //         "videoNew.mp4"
            //     )
            //     const data = this.ffmpeg.FS("readFile", "videoNew.mp4");
            //     file = new Blob([data.buffer], { type: "video/mp4" })
            // }

            // compression video
            // await this.ffmpeg.FS("writeFile", "video.mp4", await fetchFile(file));
            // await this.ffmpeg.run(
            //     "-i", "video.mp4",
            //     "-c:v", 'libx264',
            //     "-c:a", 'aac',
            //     "-crf", "50",
            //     "-preset", "ultrafast",
            //     "-reset_timestamps", "1",
            //     "-avoid_negative_ts", "1",
            //     "videoNew.mp4"
            // )
            // const data = await this.ffmpeg.FS("readFile", "videoNew.mp4");
            // file = new Blob([data.buffer], { type: "video/mp4" })

            // const videoMetaData = await this.detectVideoMetaData(file)
            // // console.log('videoMetaData', videoMetaData);
            // videoData.metaData = videoMetaData
            //
            // // video resolution control - aspect ratio must be 16/9
            // if (videoMetaData.resolution.width > 1920 && videoMetaData.resolution.aspectRatio) {
            //     // console.log('start resize')
            //     await this.resizeVideo(file)
            //     return
            // }
            //
            // // store video data
            // let fileReader = new FileReader();
            // fileReader.onload = function() {
            //     let positionStep = self.selectedStep.video
            //
            //     // store video
            //     positionStep.source = file
            //     positionStep.preview = fileURL
            //     positionStep.metaData = videoMetaData
            //     positionStep.state = 3
            //
            //     // store original video
            //     positionStep.original.source = file
            //     positionStep.original.preview = fileURL
            //     positionStep.original.metaData = videoMetaData
            //
            //     self.initSliderRange()
            // }
            // fileReader.readAsDataURL(file)
        },
        async prepareUploadedVideo(fileVideo) {
            // console.warn('prepareUploadedVideo', fileVideo)

            // let videoMetaData = await this.detectVideoMetaData(file)
            // console.log('videoMetaData 1', videoMetaData);

            // if (!videoMetaData) {
            // await this.ffmpeg.FS("writeFile", "video.mp4", await fetchFile(fileVideo));
            // await this.ffmpeg.run(
            //     "-i", "video.mp4",
            //     "-c:v", 'libx264',
            //     "-c:a", 'aac',
            //     "-crf", "30",
            //     "-preset", "ultrafast",
            //     "-reset_timestamps", "1",
            //     "-avoid_negative_ts", "1",
            //     "videoNew.mp4"
            // )
            // const data = await this.ffmpeg.FS("readFile", "videoNew.mp4");
            // let file = new Blob([data.buffer], {type: "video/mp4"})
            // }

            // let videoMetaData = await this.detectVideoMetaData(file)
            // console.log('videoMetaData 2', videoMetaData);

            const fileURL = URL.createObjectURL(fileVideo)
            let positionStep = this.selectedStep.video
            positionStep.metaData = {}
            positionStep.original.metaData = {}

            // video resolution control - aspect ratio must be 16/9
            // if (videoMetaData.resolution.width > 1920 && videoMetaData.resolution.aspectRatio) {
            //     this.loaders.uploadingVideo = false
            //
            //     // console.log('start resize')
            //     await this.resizeVideo(file)
            //     return
            // }

            // store video
            positionStep.source = fileVideo
            positionStep.preview = fileURL
            // positionStep.metaData = videoMetaData
            positionStep.state = 3

            // store original video
            positionStep.original.source = fileVideo
            positionStep.original.preview = fileURL
            // positionStep.original.metaData = videoMetaData

            // this.initSliderRange()

            this.loaders.uploadingVideo = false
        },
        async resizeVideo(file) {
            try {
                this.loaders.videoResize = true

                this.ffmpeg.FS("writeFile", "video.mp4", await fetchFile(file));
                await this.ffmpeg.run(
                    "-i", "video.mp4",
                    "-vf", "scale=1920:1080",
                    "-preset", "ultrafast",
                    "-reset_timestamps", "1",
                    "-avoid_negative_ts", "1",
                    "videoNew.mp4"
                )
                const data = this.ffmpeg.FS("readFile", "videoNew.mp4");
                const videoMetaData = await this.detectVideoMetaData(file)
                // console.log('videoMetaData', videoMetaData);

                // store video
                let positionStep = this.selectedStep.video
                // reset video data
                positionStep.source = null
                positionStep.preview = null
                positionStep.metaData = null
                // update video data
                await this.$nextTick(() => {
                    positionStep.source = data
                    positionStep.preview = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }))
                    positionStep.metaData = videoMetaData

                    // store original video
                    positionStep.original.source = data
                    positionStep.original.preview = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }))
                    positionStep.original.metaData = videoMetaData

                    this.initSliderRange()
                })
            } finally {
                this.loaders.videoResize = false
            }
        },
        /**
         * Init camera stream, check permission (audio - mic & video - camera) and start stream
         */
        async initCameraRecord(facingMode) {
            this.streamVideo = null

            let cameraSettings = {
                audio: true,
                video: {
                    width: {
                        ideal: 1920,
                        max: 1920,
                    },
                    height: {
                        ideal: 1080,
                        max: 1080,
                    },
                    aspectRatio: 16/9,
                    // mediaSource: 'window',
                    // facingMode: cameraType
                }
            }

            // only for android
            if (this.getDeviceType === "android") {
                cameraSettings.video["facingMode"] = { exact: facingMode }
            }

            await navigator.mediaDevices.getUserMedia(cameraSettings).then(stream => {
                this.streamVideo = stream

                // starting stream the video from the camera to video element
                this.streamPreviewElement.srcObject = stream;
            }).catch(e => {
                console.log('Error initScreenRecord: ', e)
                this.streamVideo = null
                this.streamAudio = null

                this.$root.$refs.ref_toast.showToast(this.$t('videoEditor.reEnableCamera'), 'toast-danger')

                let videoData = this.selectedStep.video
                videoData['sourceType'] = null
                videoData['canSelectSource'] = true
            })

            await this.initAudioRecord()
        },
        /**
         * Init audio record, check permission
         */
        async initAudioRecord() {
            this.streamAudio = null
            await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                }
            }).then(stream => {
                this.streamAudio = stream
            }).catch(e => {
                console.log('Error initAudioRecord: ', e)
                this.streamAudio = null

                this.$root.$refs.ref_toast.showToast(this.$t('videoEditor.reEnableCamera'), 'toast-danger')

                let videoData = this.selectedStep.video
                videoData['sourceType'] = null
                videoData['canSelectSource'] = true
            })
        },
        /**
         * Controls start and stop recording
         */
        handleRecord() {
            if (!this.recordingInProgress) {
                this.startRecording();
                this.recordingInProgress = true
            } else {
                this.stopRecording();
                this.recordingInProgress = false
            }
        },
        startRecording() {
            this.finalStream = new MediaStream();

            const videoTrack = this.streamVideo.getVideoTracks()[0];
            this.finalStream.addTrack(videoTrack);

            const audioTrack = this.streamAudio.getAudioTracks()[0];
            this.finalStream.addTrack(audioTrack);

            this.mediaRecorder = new MediaRecorder(this.finalStream);
            this.mediaRecorder.ondataavailable = this.recordVideo

            this.mediaRecorder.start();
        },
        async recordVideo(event) {
            if (event?.data && event?.data?.size > 0) {
                try {
                    this.loaders.convertVideoToMp4 = true

                    // turning off video transfer from the camera to video element
                    this.streamPreviewElement.srcObject = null;

                    const recordedVideo = event.data
                    // console.log("recordedVideo", recordedVideo.type)

                    this.ffmpeg.FS("writeFile", "video.webm", await fetchFile(recordedVideo));

                    // default setting for browsers, video type: "video/x-matroska;codecs=avc1,opus"
                    let videoCodec = "copy"
                    let audioCodec = "copy"

                    // for firefox, video type: 'video/webm; codecs="vp8, opus"'
                    // if (navigator.userAgent.indexOf("Firefox") !== -1) {
                        videoCodec = "libx264"
                        audioCodec = "aac"
                    // }

                    await this.ffmpeg.run(
                        "-i", "video.webm",
                        "-c:v", videoCodec,
                        "-c:a", audioCodec,
                        "-crf", "30",
                        "-preset", "ultrafast",
                        "videoNew.mp4"
                    )
                    // await this.ffmpeg.FS("unlink", "video.webm");
                    const data = await this.ffmpeg.FS("readFile", "videoNew.mp4");
                    const videoBlob = new Blob([data.buffer], { type: "video/mp4" })
                    // console.log("videoBlob type", videoBlob.type)
                    // await this.ffmpeg.FS("unlink", "videoNew.mp4");
                    const videoMetaData = await this.detectVideoMetaData(videoBlob)
                    // console.log('videoMetaData', videoMetaData);

                    // store video
                    let positionStep = this.selectedStep.video

                    // reset video data
                    positionStep.source = null
                    positionStep.preview = null
                    positionStep.metaData = null

                    // update video data
                    await this.$nextTick(() => {
                        positionStep.source = videoBlob
                        positionStep.preview = URL.createObjectURL(videoBlob)
                        positionStep.metaData = videoMetaData
                        positionStep.state = 3

                        // store original video
                        positionStep.original.source = videoBlob
                        positionStep.original.preview = URL.createObjectURL(videoBlob)
                        positionStep.original.metaData = videoMetaData

                        this.initSliderRange()
                    })
                } finally {
                    this.loaders.convertVideoToMp4 = false
                }
            }
        },
        stopRecording() {
            // if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
                this.mediaRecorder.stop();
                this.mediaStream?.getTracks().forEach(track => track.stop());
            // }

            this.streamVideo.getVideoTracks().forEach(track => track.stop())
            this.streamAudio.getAudioTracks().forEach(track => track.stop())
            this.finalStream.getAudioTracks().forEach(track => track.stop())

            this.$nextTick(() => {
                this.mediaRecorder = null
                this.streamVideo = null
                this.streamAudio = null
                this.finalStream = null
            })
        },
        /**
         * Init slider for determining video trimming times
         */
        initSliderRange() {
            let videoData = this.selectedStep.video
            const videoDuration = this.selectedStep.video.metaData.duration

            videoData.trimData = {
                max: videoDuration,
                values: [0, videoDuration]
            }
        },
        async videoTrim() {
            // console.log(this.ffmpeg);

            try {
                this.loaders.videoTrimming = true

                let videoData = this.selectedStep.video
                // console.log('videoData', videoData);

                // await this.ffmpeg.load();
                this.ffmpeg.FS("writeFile", "video.mp4", await fetchFile(videoData.source));

                const start = this.secondsFormat(videoData.trimData.values[0]) // 5.8 secs => HH:MM:ss.SS
                const end = this.secondsFormat(videoData.trimData.values[1]) // 76.4 secs => HH:MM:ss.SS

                // calculation of the time difference
                const momentStart = this.$moment(start, "HH:mm:ss.SS"); // create moment ref
                const momentEnd = this.$moment(end, "HH:mm:ss.SS"); // create moment ref
                const diff = this.$moment.duration(momentEnd.diff(momentStart));
                const formattedDiff = this.$moment.utc(diff.asMilliseconds()).format("HH:mm:ss.SS");
                // console.log('formattedDiff', formattedDiff);

                // "-crf", "23",
                // "-c:v", "libx264",
                // "-c:a", "aac",
                await this.ffmpeg.run(
                    "-ss", start,
                    "-i", "video.mp4",
                    "-to", formattedDiff,
                    "-c:v", "copy",
                    "-c:a", "copy",
                    "-reset_timestamps", "1",
                    "-avoid_negative_ts", "1",
                    "-preset", "ultrafast",
                    "videoNew.mp4",
                )
                const data = this.ffmpeg.FS("readFile", "videoNew.mp4");
                const videoBlob = new Blob([data.buffer], { type: "video/mp4" })
                // console.log("videoBlob type", videoBlob.type)
                const videoMetaData = await this.detectVideoMetaData(videoBlob)
                // console.log('videoMetaData', videoMetaData);

                // store video
                let positionStep = this.selectedStep.video
                // reset video data
                positionStep.source = null
                positionStep.preview = null
                positionStep.metaData = null
                // update video data
                await this.$nextTick(() => {
                    positionStep.source = videoBlob
                    positionStep.preview = URL.createObjectURL(videoBlob)
                    positionStep.metaData = videoMetaData

                    this.initSliderRange()
                })
            } finally {
                this.loaders.videoTrimming = false
            }
        },
        restoreVideo() {
            let videoData = this.selectedStep.video

            // reset actual video
            videoData.metaData = null
            videoData.source = null
            videoData.preview = null

            this.$nextTick(() => {
                // set original video
                videoData.source = videoData.original.source
                videoData.preview = videoData.original.preview
                videoData.metaData = videoData.original.metaData

                this.initSliderRange()
            })
        },
        clearVideoData() {
            // delete video from database
            // this.saveVideo('delete')

            this.selectedStep.video.state = 0

            let videoData = this.selectedStep.video
            // console.log('videoData', videoData)

            // clear video data
            videoData.canSelectSource = true
            videoData.metaData = null

            videoData.original.source = null
            videoData.original.preview = null
            videoData.original.metaData = null

            videoData.preview = null
            videoData.source = null
            videoData.sourceType = null
            videoData.state = 0
            videoData.trimData = null

            videoData.muxId = null

            // reset video streams
            this.streamVideo = null
            this.streamAudio = null
            this.mediaRecorder = null
            this.recordingInProgress = false

            // turning off video transfer from the camera to video element
            if (this.streamPreviewElement?.srcObject) {
                this.streamPreviewElement.srcObject = null;
            }

            // clear input value
            this.$refs.ref_media_input.value = ''

            // show source select component
            this.sourceType = null
            this.canSelectSource = true
        },
        prepareFirstVideo() {
            return new Promise(resolve => {
                let postData = {}
                postData['video1'] = this.positionSteps[0]?.video?.source

                resolve(postData)
            })
        },
        /**
         * Upload video to server or remove video from server
         * @param {string} action - 'save' - upload, 'delete' - remove
         * @returns {Promise<void>}
         */
        async saveVideo(action) {
            this.selectedStep.video.state = 2 // enable loading

            let postData = new FormData()
            postData.set('number', this.selectedStep.video.videoId)
            postData.set('video', this.selectedStep.video.source || '0')

            if (action === 'save') this.handleWindowOnBeforeUnload(true)
            this.axiosPostUpdateQuestionVideo(this.positionId, postData, updatedPercent => {
                this.updateProgress = updatedPercent === 100 ? 99 : updatedPercent;
            }).then(res => {
                // console.log('axiosPostUpdateQuestionVideo', res);
                this.updateProgress = 100

                this.selectedStep.video.state = action === 'save' ? 1 : 0
                this.selectedStep.video.source = null
                this.selectedStep.video.metaData = null
                this.selectedStep.video.trimData = null
                this.selectedStep.video.original = {
                    source: null,
                    preview: null,
                    metaData: null,
                }

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
            }, e => {
                this.selectedStep.video.state = 0

                const message = e?.response?.data?.message
                this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.handleWindowOnBeforeUnload()

                this.$nextTick(() => {
                    this.updateProgress = 0
                })
            })
        }
    },
    computed: {
        ...mapGetters({
            getDeviceType: "web/getDeviceType",
        }),
        /**
         * Selection of an actual step after clicking on an item in the menu
         */
        selectedStep() {
            return this.positionSteps.filter(step => step.id === this.actualStepId)[0]
        },
        /**
         * Whether it can change the user change the selected item
         * @returns {boolean}
         */
        canChangeStep() {
            return this.positionSteps.some(step => step?.video?.state === 2)
                || this.loaders.videoTrimming
                || this.loaders.videoResize
                || this.loaders.uploadingVideo
        },
        showVideoStreamHandler() {
            return (this.selectedStep?.video.sourceType === 'camera-user' || this.selectedStep?.video.sourceType === 'camera-environment') && !this.selectedStep?.video.preview
        },
        videoTrimProgressInPercentage() {
            // const progressTime = this.progress?.time
            // const durationTime = ((this.selectedStep?.video?.trimData?.values[1] - this.selectedStep?.video?.trimData?.values[0]) || this.selectedStep?.video?.metaData?.duration) || 60
            //
            // return progressTime >= 0
            //     ? Math.round((progressTime / durationTime) * 100)
            //     : 0

            return Math.round(this.progress?.ratio * 100)
        },
        /**
         * Return false if some video processing is in progress
         * @returns {boolean}
         */
        videoProcessing() {
            return this.loaders.videoTrimming || this.loaders.videoResize ||  this.selectedStep?.video?.state === 2
        },
        /**
         * Check all states (1 === uploaded video to BE)
         */
        allVideosSaved() {
            // when the status of the position is 0, only the first video is checked
            if (this.positionStatus?.id === 0 || this.positionStatus?.id === 3) {
                return this.positionSteps[0]?.video?.state === 1
            } else {
                return this.positionSteps.every(step => step?.video?.state === 1)
            }
        },
        /**
         * Check if there is any video saved (3 === uploaded video but not saved to BE)
         */
        someVideoUnsaved() {
            // when the status of the position is 0, only the first video is checked
            if (this.positionStatus?.id === 0 || this.positionStatus?.id === 3) {
                return this.positionSteps[0]?.video?.state === 3
            } else {
                return this.positionSteps.some(step => step?.video?.state === 3)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-video-editor::v-deep(.video-editor) {
    margin: 50px 0;

    .video-editor-container {
        padding: 30px 25px;
        border-radius: 6px;
        background: $bg--gray;

        .col-item {
            position: relative;
        }
    }

    .menu {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 365px;
    }

    .video-preview {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        //aspect-ratio: 16/9;
        min-height: 250px;
        background: $default-video-background;

        .video-preview-element {
            width: 100%;
            height: 100%;
            max-height: 400px;
            //aspect-ratio: 16/9;
            border-radius: 6px;
        }
    }

    .video-handler {
        //
    }

    .handle-video-source {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 20px 0 0;

        .component-main-button {
            flex-grow: 1;
        }
    }

    @media (max-width: 1199px) {
        .menu {
            border-bottom: 2px solid $text--gray;
        }

        .content {
            padding-top: 10px;
        }

        .video-editor-container {
            padding: 30px 5px;

            .handle-video-source {
                gap: 10px;

                .component-main-button {
                    .btn-text {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
