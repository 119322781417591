import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// css
import "./theme/global.scss"

// functions
import globalFunctions from "./functions/global"

/* Vue I18n */
import { createI18n } from 'vue-i18n'
import langTranslations from './lang/index'
const i18n = createI18n({
    locale: 'sk',
    messages: langTranslations,
    pluralizationRules: {
        'sk': function(choice) {
            // console.log('choice', choice)
            const absChoice = Math.abs(Number(choice))
            switch (absChoice) {
                case 0:
                case 1:
                    return absChoice
                case 2:
                case 3:
                case 4:
                    return 2
                default:
                    return 3
            }
        }
    }
})

/* axios */
import axios from "axios";
import config from "./config";

axios.interceptors.request.use(config => {
    // set lang to headers
    config.headers['lang'] = i18n.global?.locale || 'sk'

    // add token to headers
    if (store.getters["user/checkUserTokenExist"]) {
        config.headers['Authorization'] = `Bearer ${ store.getters["user/getUserToken"] }`
    }

    return config;
}, e => {
    return Promise.reject(e);
});
axios.interceptors.response.use(response => {
    return response;
}, e => {
    // console.warn('ERROR interceptor response', e)

    if (e.response?.status === 401 && !store.getters['web/getForceLogout']) {
        store.commit('web/setForceLogout', true);
    }

    if (e.response?.status === 404) {
        router.replace({ name: 'PageNotFound' })
    }

    return Promise.reject(e);
});

/* Moment.js */
import moment from "moment";
moment.locale('sk')

/* VueTelInput */
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";
// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

/* Fontawesome */
// import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core'
// import font awesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import specific icons
import {
    faCircleXmark,
    faCircleCheck,
    faCircleArrowUp,
    faDownload,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons'
// add icons to the library
library.add(faCircleXmark)
library.add(faCircleCheck)
library.add(faCircleArrowUp)
library.add(faDownload)
library.add(faCircleInfo)

/* Vue Inline SVG */
import InlineSvg from 'vue-inline-svg';

/* Vue Meta */
import { createMetaManager, defaultConfig } from 'vue-meta';
import { plugin as metaPlugin } from 'vue-meta'
const metaOptions = {
    ...defaultConfig,
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    // refreshOnceOnNavigation: true
};
const metaManager = createMetaManager(null, metaOptions);

// Create Vue App
const app = createApp(App)

// Use plugins
app.use(globalFunctions)
app.use(store)
app.use(router)
app.use(i18n)
app.use(VueTelInput)
app.use(VueAwesomePaginate)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('inline-svg', InlineSvg)
app.use(metaManager)
app.use(metaPlugin)

// Set global variables
app.config.globalProperties.$axios = axios
app.config.globalProperties.$config = config
app.config.globalProperties.$moment = moment

// Custom components
// import LoginModal from "./components/auth/LoginModal.vue";
// app.component('LoginModal', LoginModal)

app.mount('#app')
