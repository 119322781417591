<template>
    <div class="component-select-video-source">
        <div class="select-video-source">

            <div class="select-type">
                <div
                    class="type"
                    @click="selectSourceType('device')"
                >
                    <img class="type__icon" :src="uploadDevice" alt="upload from device icon" />
                    <div class="type__label">{{ btnTitle('fromDevice') }}</div>
                </div>

                <div
                    v-if="cameraInputExist && getDeviceType !== 'ios'"
                    class="type display-none"
                    @click="selectSourceType('camera-user')"
                >
                    <inline-svg
                        class="type__icon"
                        :src="cameraUser"
                    />
                    <div class="type__label">{{ btnTitle('camera') }}</div>
                </div>

                <div
                    v-if="cameraInputExist && getDeviceType === 'android'"
                    class="type display-none"
                    @click="selectSourceType('camera-environment')"
                >
                    <inline-svg
                        class="type__icon"
                        :src="cameraEnvironment"
                    />
                    <div class="type__label">{{ btnTitle('cameraEnvironment') }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import uploadDevice from "../../../assets/icons/videoEditor/uploadDevice.svg"
import cameraUser from "../../../assets/icons/videoEditor/cameraUser.svg"
import cameraEnvironment from "../../../assets/icons/videoEditor/cameraEnvironment.svg"
import { mapGetters } from "vuex";

export default {
    name: "SelectVideoSource",
    data() {
        return {
            uploadDevice, cameraUser, cameraEnvironment,

            cameraInputExist: false,
        };
    },
    mounted() {
        this.getUserSelectMedia()
    },
    methods: {
        selectSourceType(val) {
            this.$emit('select-source', val)
        },
        async getUserSelectMedia() {
            const sources = await navigator.mediaDevices.enumerateDevices()
            // whether there is a camera input
            this.cameraInputExist = sources.some(source => source.kind === 'videoinput')
        }
    },
    computed: {
        ...mapGetters({
            getDeviceType: "web/getDeviceType"
        }),
        btnTitle() {
            return type => this.$t('videoEditor.selectSourceType.' + type)
        }
    }
};
</script>

<style lang="scss" scoped>
.component-select-video-source::v-deep(.select-video-source) {
    margin-top: 20px;

    .select-type {
        display: flex;
        align-items: stretch;
        gap: 15px;

        .type {
            flex: 1;

            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 10px 0 5px;

            text-align: center;
            cursor: pointer;
            border-radius: 3px;
            background: $white;

            &__icon {
                fill: $primary;
                height: 33px;
            }

            &__label {
                margin-top: 3px;
                font-weight: 600;
            }
        }
    }
}
</style>
