<template>
    <div class="page page-home">
        <div class="page-container">
            <BackgroundVideo />

            <WhatIsZealy />

            <CareerDirection />

            <div class="positions-filter container">
                <PositionParametersForm
                    form-type="filter"
                    ref="page_home_position_filter_form"
                />

                <MainButton
                    color-mode="secondary"
                    :btn-text="$t('search')"
                    :is-loading="loaders.submitForm"
                    @click.prevent="searchPositions"
                />
                <div class="cancel-filter" @click.prevent="cancelFilter">
                    {{ $t('cancelFilter') }}
                </div>
            </div>

            <div class="positions-list-wrapper">
                <div class="container">
                    <div class="profiles-count">
                        {{ $t('profilesCount') }}:
                        <span v-if="loaders.getPositions">...</span>
                        <span v-else>{{ pagination.totalItems }}</span>
                    </div>

                    <div class="spinner-wrapper text-center" v-if="loaders.getPositions">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <PositionsList
                        v-if="positionsList.length && pagination.totalItems"
                        :positions="positionsList"
                        :pagination="pagination"
                        :loading="loaders.getPositions"
                        @pagination-handle-current-page="handlePagination"
                    />

<!--                    <div-->
<!--                        v-if="!positionsList.length && !loaders.getPositions"-->
<!--                        class="no-results text-center"-->
<!--                    >-->
<!--                        {{ $tc('noResults', 1) }}-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackgroundVideo from "../components/dashboard/BackgroundVideo.vue";
import WhatIsZealy from "../components/dashboard/WhatIsZealy.vue";
import CareerDirection from "../components/dashboard/CareerDirection.vue";
import positionService from "../functions/services/positionService";
import PositionsList from "../components/position/PositionsList.vue";
import PositionParametersForm from "../components/position/PositionParametersForm.vue";
import MainButton from "../components/elements/MainButton.vue";
import { mapGetters } from "vuex";

export default {
    name: 'PageHome',
    mixins: [ positionService ],
    components: {
        MainButton,
        PositionParametersForm,
        PositionsList,
        BackgroundVideo,
        WhatIsZealy,
        CareerDirection,
    },
    data() {
        return {
            positionsList: [],
            pagination: {
                currentPage: 1,
                perPage: 20,
                totalItems: 0,

                nextPageUrl: null
            },
            loaders: {
                getPositions:  false
            }
        };
    },
    mounted() {
        this.getPositionsList()
    },
    watch: {
        getUserToken: {
            handler() {
                this.getPositionsList()
            },
            deep: true
        }
    },
    methods: {
        async getPositionsList() {
            this.loaders.getPositions = true

            const queryParams = this.$route.query

            let bodyParams = []
            Object.keys(queryParams).forEach(key => {
                bodyParams.push({ key: key, value: queryParams[key] });
            })
            bodyParams.push({ key: 'per_page', value: this.pagination.perPage });

            this.axiosGetPositionList(this.checkUserLoggedIn, bodyParams).then(res => {
                // console.log('getPositionsList', res);
                const data = res.data

                // handle pagination & data
                this.pagination.currentPage = data.meta?.current_page
                this.pagination.totalItems = data.meta?.total || 0
                this.pagination.nextPageUrl = data.links?.next || null

                // store data
                this.positionsList = data.data
            }).finally(() => {
                this.loaders.getPositions = false
            })
        },
        async handlePagination() {
            await this.updateQueryParam('page', this.pagination.currentPage)
            await this.getPositionsList()

            setTimeout(() => {
                document.querySelector('.cancel-filter')?.scrollIntoView({ behavior: 'smooth' });
            }, 500)
        },
        async searchPositions() {
            this.pagination.currentPage = 1
            this.pagination.totalItems = 0
            this.pagination.nextPageUrl = null

            // prepare queries from filter
            const params = await this.$refs.page_home_position_filter_form.submitData()
            let paramsCopy = JSON.parse(JSON.stringify(params))
            for (let key in paramsCopy) {
                const value = paramsCopy[key]
                if (
                    value !== null &&
                    value !== undefined &&
                    value !== '' &&
                    !(typeof value === 'number' && isNaN(value)) &&
                    !(Array.isArray(value) && value.length === 0)
                ) {
                    // bodyParams.push({ key: key, value: data[key] });
                } else {
                    delete paramsCopy[key]
                }
            }
            await this.updateQueryParams(paramsCopy)

            this.getPositionsList()
        },
        async cancelFilter() {
            this.pagination.currentPage = 1
            this.pagination.totalItems = 0
            this.pagination.nextPageUrl = null

            // reset filter
            this.$refs.page_home_position_filter_form.resetForm()

            await this.updateQueryParams({})

            this.$nextTick(() => {
                this.getPositionsList([])
            })
        },
        // handleNextPage() {
        //     if (this.pagination.nextPageUrl) {
        //         this.loaders.getPositions = true
        //         return this.$axios.get(this.pagination.nextPageUrl, this.$config.HEADERS).then(res => {
        //             // console.log('handleNextPage', res);
        //             const data = res.data
        //
        //             this.positionsList = [...this.positionsList, ...data.data]
        //             this.pagination.nextPageUrl = data.links.next || null
        //             this.pagination.currentPage = data.meta.current_page
        //         }).finally(() => {
        //             this.loaders.getPositions = false
        //         })
        //     }
        // },
    },
    computed: {
        ...mapGetters({
            getUserToken: 'user/getUserToken',
        })
    }
}
</script>

<style lang="scss" scoped>
.page-home::v-deep(.page-container) {
    padding-top: 0;

    .positions-list-wrapper {
        padding: 50px 0;
        background: $bg--gray;

        .profiles-count {
            text-align: center;
            font-style: italic;
            color: $text--gray;
        }

        .component-positions-list {
            margin-top: 30px;
        }
    }

    .positions-filter {
        padding-top: 50px;
        padding-bottom: 50px;

        .component-main-button {
            margin-top: 20px;
        }

        .cancel-filter {
            margin-top: 10px;

            font-size: 14px;
            text-align: center;
            color: $text--gray;
            cursor: pointer;
        }
    }
}
</style>
