<template>
    <div class="page page-position-create">
        <div class="page-container container">

            <div class="row row-items">
                <!-- SECTION LEFT SIDE PANEL -->
                <div class="col-item col-12 order-3 order-xl-1 col-xl-4 mt-3 mb-3">
                    <InfoCard
                        class="mb-5"
                        v-if="!enteredIban"
                        lang-key="iban"
                        @click="redirectToPage('PageProfile')"
                    />

                    <PositionCreateInfographics />
                </div>

                <!-- SECTION RIGHT SIDE PANEL -->
                <div class="col-item col-12 order-1 order-xl-2 col-xl-8 mt-3 mb-3">
                    <ProfileExtendedForm
                        page-location="create-position"
                        :errors="errors"
                        ref="ref_position_create_profile_form"
                    />

                    <PositionParametersForm
                        mode="create"
                        form-type="form"
                        :errors="errors"
                        ref="ref_position_param_form_create"
                    />
                    <!-- SECTION CONSENT -->
                    <div class="position-create-consent">
                        <TheInput
                            input-id="inputNameConsent"
                            input-name="name"
                            v-model:value="formData.name"
                            :is-required="true"
                            :input-label="$t('name.label')"
                            :input-placeholder="$t('name.placeholder')"
                            :error-text="showInputError('name')"
                            @start-validate="startPartialValidation('name')"
                        />

                        <TheInput
                            input-id="inputSurnameConsent"
                            input-name="surname"
                            v-model:value="formData.surname"
                            :is-required="true"
                            :input-label="$t('surname.label')"
                            :input-placeholder="$t('surname.placeholder')"
                            :error-text="showInputError('surname')"
                            @start-validate="startPartialValidation('surname')"
                        />

                        <div class="consents-wrapper">
                            <div class="input-container form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="checkAgreeWithConsent"
                                    name="consent"
                                    v-model="formData.acceptConsent"
                                >
                                <label class="form-check-label" for="checkAgreeWithConsent">
                                    {{ $t('registrationConsents.agreeWith') }}
                                    <a class="link" :href="getWebSettingsByKey('vop_professional')" target="_blank">
                                        {{ $t('registrationConsents.consent') }}
                                    </a>
                                    &nbsp;<span class="attribute-required">*</span>
                                </label>
                                <div class="error-text">{{ showInputError('acceptConsent') }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SECTION BOTTOM PANEL -->
                <div class="col-item col-12 order-2 order-xl-3">
                    <MainButton
                        class="mt-4"
                        btn-type="submit"
                        color-mode="primary"
                        :btn-text="$t('saveDraft')"
                        :is-loading="loaders.saveDraft"
                        :is-disabled="loaders.sendForControl"
                        @click.prevent="submitForm(true)"
                    />

                    <VideoEditor
                        mode="create"
                        @video-processing="videoProcessing = $event"
                        @some-video-unsaved="someVideoUnsaved = $event"
                        ref="ref_video_editor_create"
                    />

                    <!--            <HorizontalProgressBar-->
                    <!--                v-if="updateProgress"-->
                    <!--                :current-value="updateProgress"-->
                    <!--            />-->

                    <PositionCreateButtons
                        :class="{ 'disable-element': videoProcessing }"
                        :save-draft="loaders.saveDraft"
                        :send-for-check="loaders.sendForControl"
                        @save-position="submitForm"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validation from "../../functions/validation";
import { useVuelidate } from "@vuelidate/core/dist";
import { required } from '@vuelidate/validators'
import positionService from "../../functions/services/positionService";
import PositionParametersForm from "../../components/position/PositionParametersForm.vue";
import VideoEditor from "../../components/videoEditor/VideoEditor.vue";
import ProfileExtendedForm from "../../components/profile/ProfileExtendedForm.vue";
import userService from "../../functions/services/userService";
import PositionCreateButtons from "../../components/position/elements/PositionCreateButtons.vue";
import InfoCard from "../../components/elements/InfoCard.vue";
import { mapGetters, mapMutations } from "vuex";
import MainButton from "@/components/elements/MainButton.vue";
import PositionCreateInfographics from "@/components/position/PositionCreateInfographics.vue";
import TheInput from "@/components/elements/TheInput.vue";

export default {
    name: "PagePositionCreate",
    mixins: [ validation, positionService, userService ],
    components: {
        TheInput,
        PositionCreateInfographics,
        MainButton,
        InfoCard,
        PositionCreateButtons,
        ProfileExtendedForm,
        VideoEditor,
        PositionParametersForm,
    },
    metaInfo() {
        return {
            title: 'Profesijný profil',
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            userData: {},
            updateProgress: 0,
            loaders: {
                saveDraft: false,
                sendForControl: false,
            },
            errors: {},

            formData: {
                name: '',
                surname: '',
                acceptConsent: false,
            },

            videoProcessing: false, // the video is being processed (upload, trimming, ...)
            someVideoUnsaved: false
        };
    },
    watch: {
        preventRefresh: {
            handler(val) {
                val
                    ? this.handleWindowOnBeforeUnload(true)
                    : this.handleWindowOnBeforeUnload(false)
            },
            immediate: true
        }
    },
    mounted() {
        this.getUserProfileData()
    },
    beforeUnmount() {
        this.handleWindowOnBeforeUnload(false)
    },
    methods: {
        ...mapMutations({
            setUserRole: 'user/setUserRole',
        }),
        /**
         * After leaving the input, start the validation of the given value
         * @param {string} key - data key
         * @example 'actualPositionName'
         */
        startPartialValidation(key) {
            const path = 'formData.' + key
            path.split('.').reduce((a, b) => this.v$[a][b].$touch())
        },
        async submitForm(draft) {
            this.v$.$reset()

            if (draft) {
                this.v$.formData.$touch()

                // editor profile data validation
                this.$refs.ref_position_create_profile_form.v$.$touch()
                // position data validation - validate only title (must be filled)
                this.$refs.ref_position_param_form_create.v$.formData.positionTitle.$touch();

                if (
                    this.$refs.ref_position_create_profile_form.v$.$invalid
                    || this.$refs.ref_position_param_form_create.v$.formData.positionTitle.$invalid
                    || this.v$.formData.$invalid
                ) {
                    await this.$nextTick(() => this.scrollToError())
                    return
                }
            } else {
                // validate entire form (editor profile form + position data form)
                this.v$.$touch()
                if (this.v$.$invalid) {
                    await this.$nextTick(() => this.scrollToError())
                    return
                }
            }

            // check first video
            const videoData = await this.$refs.ref_video_editor_create.prepareFirstVideo()
            if (!draft && !videoData['video1']) {
                this.$root.$refs.ref_toast.showToast(this.$t('videoEditor.firstVideoUploadWarning'), 'toast-danger')
                return
            }

            draft
                ? this.loaders.saveDraft = true
                : this.loaders.sendForControl = true

            try {
                let postData = new FormData()
                postData.set('draft', draft ? '1' : '0')

                // prepare profile data
                const profileData = await this.$refs.ref_position_create_profile_form.submitData()
                Object.keys(profileData).forEach(key => {
                    postData.set(key, profileData[key])

                    // const val = profileData[key]
                    // if (val === "0" || (!isNaN(val) && parseFloat(val) > 0) || (typeof val === "string" && val.length > 0)) {
                    //     postData.set(key, profileData[key])
                    // }
                })

                // prepare position data
                const positionData = await this.$refs.ref_position_param_form_create.submitData()
                Object.keys(positionData).forEach(key => {
                    const value = positionData[key]
                    if (value === null || value === undefined) {
                        postData.set(key, '')
                    } else if (Array.isArray(value)) {
                        value.forEach((id, index) => postData.set(`${key}[${index}]`, id))
                    } else if (typeof value === 'boolean') {
                        postData.set(key, positionData[key] ? '1' : '0')
                    } else {
                        postData.set(key, positionData[key])
                    }
                })

                // prepare video
                if (videoData['video1']) postData.set('video1', videoData['video1'])

                // prepare consent
                postData.set('consent_name', this.formData.name)
                postData.set('consent_surname', this.formData.surname)
                postData.set('consent', this.formData.acceptConsent ? '1' : '0')

                this.createPosition(postData)
            } catch {
                this.loaders.saveDraft = false
                this.loaders.sendForControl = false
            }
        },
        createPosition(postData) {
            return new Promise(() => {
                // call axiosPostPositionCreate with a callback function to update percentCompleted
                this.axiosPostPositionCreate(postData, updatedPercent => {
                        this.updateProgress = updatedPercent === 100 ? 99 : updatedPercent;
                })
                    .then(res => {
                        // console.log('createPosition', res);
                        this.updateProgress = 100

                        this.videoProcessing = false
                        this.someVideoUnsaved = false

                        // set new user role - editor role
                        if (this.getUserRole !== 'editor') this.setUserRole('editor')

                        this.$nextTick(() => {
                            this.$router.replace({ name: 'PagePositionsOwned' })
                            this.$root.$refs.ref_toast.showToast(res.data.message, 'toast-success')
                        })
                    }, e => {
                        const data = e.response?.data
                        this.errors = data?.errors
                        this.$root.$refs.ref_toast.showToast(data?.message, 'toast-danger')

                        this.$nextTick(() => this.scrollToError())
                    }).finally(() => {
                        this.loaders.saveDraft = false
                        this.loaders.sendForControl = false

                        this.$nextTick(() => {
                            this.updateProgress = 0
                        })
                    })
            });
        },
        scrollToError() {
            setTimeout(() => {
                const allErrorDivs = document.querySelectorAll('.error-text') // select all errors divs
                let allErrorDivsCopy = [...allErrorDivs] // copy for array function
                allErrorDivsCopy = allErrorDivsCopy.filter(div => div.textContent) // select only displayed (no empty) errors

                const firstError = allErrorDivsCopy[0]?.parentElement?.parentElement
                if (firstError) firstError.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 100)
        },
        getUserProfileData() {
            this.axiosGetUserProfileData().then(res => {
                // console.log('getUserProfileData', res);
                const data = res.data.data

                this.userData = data
                this.$refs.ref_position_create_profile_form?.prefillUserData(data)
            })
        },
    },
    computed: {
        ...mapGetters({
            getUserRole: 'user/getUserRole',
            getUserDataByKey: 'user/getUserDataByKey',
            getWebSettingsByKey: 'web/getWebSettingsByKey'
        }),
        preventRefresh() {
            return this.videoProcessing || this.someVideoUnsaved
        },
        /**
         * Whether the user has entered an iban or not
         */
        enteredIban() {
            return !!this.getUserDataByKey("iban")
        },
        showInputError() {
            return key => {
                const keyValuePath = 'formData.' + key
                return this.validationErrorHandler(this.v$, keyValuePath)
            }
        },
    },
    validations() {
        return {
            formData: {
                name: {
                    required
                },
                surname: {
                    required
                },
                acceptConsent: {
                    sameAs(val) {
                        return val
                    }
                },
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.page-position-create::v-deep(.page-container) {
    .component-position-parameters-form {
        .parameters-form-title {
            margin-top: 20px;
        }
    }
}
</style>
